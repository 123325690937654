// import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import translate from '../i18n/translate';
import { LOCALES } from '../i18n';
import amzURL from '../assets/AMZ_PRM_logo.png';
import lotrURL from '../assets/LOTR_TROP_Lockups_DT_EN.svg';
import lotrmbURL from '../assets/LOTR_TROP_Lockups_MB_EN.svg';
import lotrURL_fr from '../assets/LOTR_TROP_Lockups_DT_FR.svg';
import lotrmbURL_fr from '../assets/LOTR_TROP_Lockups_MB_FR.svg';

export default function Header({handleLanguage,locale}){
  const navigate = useNavigate();

  function handleLanguageClick(){
    if (locale===LOCALES.ENGLISH){
      navigate('/fr');
    } else {
      navigate('/');
    }
    handleLanguage();
  }
  function goHome(){
    if (locale===LOCALES.ENGLISH){
      navigate('/');
    } else {
      navigate('/fr');
    }
  }

  const dt_logo = (locale===LOCALES.ENGLISH) ? lotrURL : lotrURL_fr;
  const mb_logo = (locale===LOCALES.ENGLISH) ? lotrmbURL : lotrmbURL_fr;

  return (
      <>
        <header className="App-header">
          <button className='toggle' onClick={handleLanguageClick}>{translate('languageToggle')}</button>
          <img src={amzURL} className="AMZ-logo" alt='Amazon Prime Video' />
          <img src={dt_logo} onClick={goHome} className="LOTR-logo" alt='The Lord of the Rings - The Rings of Power - A Taste of Middle Earth' />
          <img src={mb_logo} onClick={goHome} className="LOTR-logo-mb" alt='The Lord of the Rings - The Rings of Power - A Taste of Middle Earth' />
        </header>
      </>
  );
}