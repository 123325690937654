const axios = require('axios');

let api = '';
// let apiPath = '';
let apiPostfix = '';

// let localsettings = '';

// let session;
let _time = new Date(2022,9,7,20,1);

export async function init(_apiPath,_apiPostfix){
    // console.log('init');
    api = axios.create({ baseURL: _apiPath });
    // apiPath = _apiPath;
    apiPostfix = _apiPostfix;

    await getTimeAsync();
    return true;
}

export async function getTimeAsync(){
    // console.log('getTime',_time);
    if (!_time) {
        const { data:result } = await api.get('time'+apiPostfix);
        // console.log(result);
        /* / temporary testing strings.
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
        if (params.m){
            console.log('m',params.m,params.d,params.h,params.i);
            const today = new Date();
            const m = params.m;
            const d = params.d || today.getDate();
            const h = params.h || today.getHours();
            const i = params.i || today.getMinutes();
            _time = new Date(2022,m,d,h,i).getTime();
            console.log(_time);
        } else { */
            _time = new Date(result.init).getTime();
        //}
        
    }
    return _time;
}
export function getTime(){
    if (_time) return _time;
}

export async function confirmEntry(id){
    const { data:result } = await api.post('confirm'+apiPostfix,{
        id:id
    });
    return result;
}
export async function submitEntry(submission){
    const { data:result } = await api.post('submit'+apiPostfix, submission);
    console.log(result);
    return result;
}
export function resetSession(){
    // session = null;
}

// todo. error handling.