// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import translate from '../../i18n/translate';

export default function ThankYou(){
    // const navigate = useNavigate();

    return (
        <div>
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
                <h1>{translate('thankyou.title')}</h1>
                <p>{translate('thankyou.body')}</p>
            </Tween>
        </div>
    );
}