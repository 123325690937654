// const preconteststate = -1;
// const conteststate = 0;
// const postconteststate = 1;
import { getTime } from "../components/Model";

const drops = [ 
    new Date("2022-10-08T00:00:00.000Z"),
    new Date("2022-10-09T00:00:00.000Z"),
    new Date("2022-10-10T00:00:00.000Z")
];

const thisStartTime = new Date();
const contestWindow = 10 * 60 * 1000; // 10 minute window
let timetilnextdrop;
let remainingTime;
let thisdrop;

export function getContestState(){

    // check qs state
    let state = null;

    // let today = window.reactInit.initdate; //new Date("2021/08/15 11:59:00"); // 
    let elapsed = new Date() - thisStartTime;
    // console.log(getTime(),elapsed);
    let today = new Date( getTime() + elapsed);
    // console.log(today);

    for(let i=0;i<drops.length;i++){
        const diff = today - drops[i];
        // console.log('diff',drops[i],i,diff);
        if (diff < 0) { // not there yet.
            // console.log('not there yet');
            timetilnextdrop = Math.ceil(-diff/1000);
            remainingTime = 0;
            state = -1;
            break;
        } else if (diff < contestWindow) {
            // console.log('in there');
            timetilnextdrop = 0;
            thisdrop = (i+1);
            remainingTime = Math.ceil((contestWindow-diff) /1000);
            state = 1;
            break;
        } else {
            // console.log('past');
        }
    }

    // checked all dates.
    if (state===null){
        state = 0; // no more drops!
    }

    return state;
}
export function getNextDrop(){
    getContestState();
    // console.log('next drop',timetilnextdrop);
    return timetilnextdrop;
}
export function getNextDropDates(){
    let elapsed = new Date() - thisStartTime;
    // console.log(elapsed);
    let today = new Date( getTime() + elapsed);
    // console.log(today);

    let remainingDrops = [];

    for(let i=0;i<drops.length;i++){
        const diff = today - drops[i];
        // console.log('diff',drops[i],i,diff);
        if (diff < 0) { // not there yet.
            // console.log('not there yet');
            remainingDrops.push(drops[i]);
        } else if (diff < contestWindow) {
            // console.log('in there');
        } else {
            // console.log('past');
        }
    }

    return remainingDrops;

}
export function getTimeRemaining(){
    getContestState();
    return remainingTime;
}
export function getCurrentDrop(){
    // this should get called right after getTimeRemaining so no need to refresh contest state.
    return thisdrop;
}
// export drops;