// import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LOCALES } from '../i18n';
import translate from '../i18n/translate';

const flinks = [
    // ['legal','/legal'],
    ['faq',{en:'/faq', fr:'/fr/faq'} ],
    ['contact',{en:'mailto:info@atasteofmiddle-earth.com',fr:'mailto:info@atasteofmiddle-earth.com'}],
    ['privacy',{en: 'https://www.amazon.ca/gp/help/customer/display.html?nodeId=918814&ref_=footer_privacy&language=en_CA',
                fr: 'https://www.amazon.ca/gp/help/customer/display.html?nodeId=918814&ref_=footer_privacy&language=fr_CA' } ],
    ['terms',{en:'/terms',fr:'/fr/terms'} ],
];

export default function Footer({locale}){
    return (
        <>
          <footer className="App-footer">
            {/* todo. legal copy? */}
            { flinks.map((flink)=>{
                    const link = locale===LOCALES.FRENCH? flink[1].fr : flink[1].en;
                    if (link.substr(0,4)==='http'){
                        return (<li key={flink[0]}><a href={link} target="_blank" rel="noreferrer">{ translate(flink[0]) }</a></li>);
                    } else if (link.substr(0,6)==='mailto'){
                        return (<li key={flink[0]}><a href={link}>{ translate(flink[0]) }</a></li>);
                    } else {
                        return (<li key={flink[0]}><Link to={link}>{ translate(flink[0]) }</Link></li>);
                    }
                    
                }) }
          </footer>
        </>
    );
}