import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'A Taste of Middle-Earth',
        'close': 'Close',
        'legal': 'Legal',
        'faq': 'General Info',
        'privacy': 'Privacy Policy',
        'terms': 'Terms and Conditions',
        'contact': 'Contact Us',
        'languageToggle': 'FR',

        'cookie.title': 'Your Cookie Settings',
        'cookie.body': 'We use cookies to enhance your browsing experience, to analyze our website traffic and to understand where our visitors come from. If you consent to cookies, click “Accept”. If you’d like, you can change your cookies through your browser settings. For more information, view our ',
        'cookie.cta': 'Accept',

        'splash.title' : 'Tonight, We Dine in Middle-earth',
        'splash.countdown.label': 'Time until next drop',
        'splash.countdown.mins': 'min',
        'splash.countdown.hrs': 'hr',
        'splash.upcoming.title': 'Upcoming drop dates',
        'splash.upcoming.format': '{m}.{d}.2022 8 PM EST (5 PM PST)',
        'splash.body': 'Celebrate <i>The Lord of the Rings:</i> <i>The Rings of Power</i> season finale with “A Taste of Middle-earth”—an exclusive gourmet meal kit, complete with Middle-earth–inspired cutlery and plates, delivered right to your home!{br}{br}Choose between the flavourful vegetarian Harfoot Feast and the delectable Dwarven Dish with lamb, all created by celebrity Iron Chef Marcus Samuelsson.',
        'splash.subtitle': 'How the Drop Works',
        'splash.body2': 'Quantities are limited, so mark your calendars, set your sundials, and get ready to take your taste buds to Middle-earth.{br}{br}Our meal kits will be dropped exclusively on October 7, 8 and 9 at 8 PM EST (5 PM PST), one week before <i>The Lord of the Rings:</i> <i>The Rings of Power</i> season finale. {br}{br}Follow @PrimeVideoCA for\u00a0more\u00a0details.',
        'splash.areas.title': 'The following cities will have a limited\u00a0release:',
        'splash.areas.1': 'Greater Toronto Area',
        'splash.areas.2': 'Greater Montreal Area',
        'splash.areas.3': 'Greater Vancouver Area',
        'splash.cta': 'ORDER YOUR MEAL KIT',
        'splash.closed.title': 'Sorry, the Offer Is No Longer Available',
        'splash.closed.body': 'While the “A Taste of Middle-earth” meal kit offer is no longer available, you can still stream <i>The Lord of the Rings:</i> <i>The Rings of Power</i> on Amazon Prime Video. Stay tuned for the series finale on October 14, 2022.{br}{br}And, to create your own <i>The Lord of the Rings:</i> <i>The Rings of Power</i>–inspired recipes from Middle-earth, use your Alexa-enabled device and say, “Alexa, show me <i>The Rings of Power</i> recipes.”',

        'signup.body': 'Fill in the form below to claim your “A Taste of Middle-earth” meal kit.',
        'signup.time': 'Time remaining',
        'signup.shipping.title': 'Shipping Information',
        'signup.shipping.address1': '123 Main St.*',
        'signup.shipping.address2': 'Unit Address Line 2 (optional)',
        'signup.shipping.city': 'City*',
        'signup.shipping.postal': 'M1R 2B3*',
        'signup.shipping.province': 'ON*',
        'signup.personal.title': 'Personal Information',
        'signup.personal.firstname': 'First Name*',
        'signup.personal.lastname': "Last Name*",
        'signup.personal.email': 'Email Address*',
        'signup.personal.phone': 'Phone Number*',
        'signup.meal.title': 'Select Your Meal',
        'signup.meal.option1.label': 'Harfoot Feast (Vegetarian)',
        'signup.meal.option1.desc': 'Roasted Cabbage with Farro, Hazelnuts, Wild Mushrooms and Blackberries',
        'signup.meal.option2.label': 'Dwarven Dish',
        'signup.meal.option2.desc': 'Spiced Lamb Shoulder Chops with Salt-Roasted Beets and Herbed Labneh',
        'signup.optin': 'I consent to receiving updates and communication on the meal kits.*',
        'signup.age': 'I acknowledge I’m 18 years or older.*',
        'signup.confirm': 'Please confirm all the information above. This cannot be changed later.',
        'signup.error': 'Please fill in all missing fields.',
        'signup.cta': 'Submit',

        'signup.error.postal.title': 'Looks like you’re out of the delivery zone.',
        'signup.error.postal.body': 'Sorry, our horses can only deliver to the Greater Toronto, Montreal and Vancouver areas',
        'signup.error.postal.cta': 'Go Back',
        'signup.error.dupe.title': 'Looks like you’ve already signed up.',
        'signup.error.dupe.body': 'Sorry, you can only enter this giveaway once per day. Watch out for our next drop!',
        'signup.error.dupe.cta': 'Go Back',
 
        'thankyou.title': 'Thank you!',
        'thankyou.body': 'We’ll inform you if you’ve secured a meal kit. Don’t forget to check your spam folder.',

        'confirm.title': 'Thank you for confirming your spot!',
        'confirm.body': 'Look out for an email with tracking information once the meal kit has been shipped.',
        'confirm.error': 'Sorry, you did not confirm within the 24-hour window. Your meal kit will not be delivered.',

        'faq.title': 'General Info',
        'faq.body': `<h4>Shipment</h4>
        <p>Once the meal kit has been shipped, you’ll receive an email or text with tracking information.</p>
        
        <h4>Changing shipping addresses</h4>
        <p>Shipping addresses cannot be changed. We will ship to the address entered when you signed up for the giveaway.</p>
        
        <h4>Cancelling orders</h4>
        <p>Once you have claimed your meal kit, your order cannot be cancelled or modified.</p>
        
        <h4>Tracking orders</h4>
        <p>Please refer to the tracking information in the email or text you receive.</p>
        
        <h4>Return policy</h4>
        <p>We cannot accept any returns on this giveaway.</p>`
        
    }
}