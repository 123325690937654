// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import translate from '../../i18n/translate';

export default function Faq(){
    // const navigate = useNavigate();

    return (
        <div>
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
                <h1>{translate('faq.title')}</h1>
                <div className="faq-body">{translate('faq.body',{h4:chunks=><h4>{chunks}</h4>,p:chunks=><p>{chunks}</p>})}</div>
            </Tween>
        </div>
    );
}