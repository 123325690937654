import { useRef, useEffect, useState } from 'react';
import translate from '../i18n/translate';
import { getNextDropDates } from '../util';

export default function Countdown({onComplete,start}){
    
    const [ count, setCount ] = useState(start);
    const nextDropDates = getNextDropDates();
    
    useInterval(()=>{
        // console.log('tick',count);
        if (count===0){ onComplete(); }
        // if (count>=0){
        //     clearInterval(timer);
        // } else {
            setCount(count-1);
        // }
    }, 1000);

    return (
      <div className="countdown">
          <span>{translate('splash.countdown.label')}</span>
          <small style={{display:'none'}}>{count}</small>
          <h2>
              {Math.ceil(count/60)/60>1 && <>{Math.floor(Math.ceil(count/60)/60)}<small>&nbsp;{translate('splash.countdown.hrs')}{Math.floor(Math.ceil(count/60)/60)>1&&'s'}</small></> }
              <> </> 
              {Math.ceil(count/60)===60
                ? Math.ceil(count/60)
                : Math.ceil(count/60)%60}<small>&nbsp;{translate('splash.countdown.mins')}{((Math.ceil(count/60)%60>1)||Math.ceil(count/60)===60)&&'s'}</small></h2>
          <span>{translate('splash.upcoming.title')}</span>
          <ul>
            {nextDropDates.map( (date) => {
              return <li key={date}>
                {translate('splash.upcoming.format',{m:date.getMonth()+1, d:date.getDate()})}
                </li>;
            })}
          </ul>
      </div>
    );
}


function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}