import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitEntry } from '../Model';
import translate from '../../i18n/translate';
import { LOCALES } from '../../i18n';
import { useIntl } from 'react-intl';
import { Tween } from 'react-gsap';
import MiniCountdown from '../MiniCountdown';
import { getTimeRemaining, getCurrentDrop } from '../../util';
import Select from 'react-select';
// import { Tween } from 'react-gsap';

const GTA = 'K2H,K4R,K7V,K8P,L0B,L0C,L0E,L0G,L0H,L0J,L0K,L0P,L0R,L1B,L1C,L1E,L1G,L1H,L1J,L1K,L1L,L1M,L1N,L1P,L1R,L1S,L1T,L1V,L1W,L1X,L1Y,L1Z,L2A,L2E,L2J,L2M,L2N,L2P,L2S,L2T,L3B,L3J,L3L,L3P,L3R,L3S,L3T,L3X,L3Y,L4A,L4B,L4C,L4E,L4G,L4H,L4J,L4K,L4L,L4P,L4R,L4S,L4T,L4V,L4W,L4X,L4Y,L4Z,L5A,L5B,L5C,L5E,L5G,L5H,L5J,L5K,L5L,L5M,L5N,L5P,L5R,L5S,L5T,L5V,L5W,L6A,L6B,L6C,L6E,L6G,L6H,L6J,L6K,L6L,L6M,L6P,L6R,L6S,L6T,L6V,L6W,L6X,L6Y,L6Z,L7A,L7B,L7C,L7E,L7G,L7J,L7K,L7L,L7M,L7N,L7P,L7R,L7S,L7T,L8G,L8J,L8L,L8M,L8P,L8R,L8S,L9E,L9J,L9K,L9L,L9N,L9P,L9T,L9X,M1B,M1C,M1E,M1G,M1H,M1J,M1K,M1L,M1M,M1N,M1P,M1R,M1S,M1T,M1V,M1W,M1X,M2H,M2J,M2K,M2L,M2M,M2N,M2P,M2R,M3A,M3B,M3C,M3H,M3J,M3K,M3L,M3M,M3N,M4A,M4B,M4C,M4E,M4G,M4H,M4J,M4K,M4L,M4M,M4N,M4P,M4R,M4S,M4T,M4V,M4W,M4X,M4Y,M5A,M5B,M5C,M5E,M5G,M5H,M5J,M5K,M5L,M5M,M5N,M5P,M5R,M5S,M5T,M5V,M5W,M5X,M6A,M6B,M6C,M6E,M6G,M6H,M6J,M6K,M6L,M6M,M6N,M6P,M6R,M6S,M7A,M7R,M7Y,M8V,M8W,M8X,M8Y,M8Z,M9A,M9B,M9C,M9L,M9M,M9N,M9P,M9R,M9V,M9W,N0B,N1E,N1G,N1H,N1M,N2T,N4X,N5A,P0X,P4N,R0B';
const GMA = 'G3N,G3S,G5Z,G7A,H1A,H1B,H1C,H1E,H1G,H1H,H1J,H1K,H1L,H1M,H1N,H1P,H1R,H1S,H1T,H1V,H1W,H1X,H1Y,H1Z,H2A,H2B,H2C,H2E,H2G,H2H,H2J,H2K,H2L,H2M,H2N,H2P,H2R,H2S,H2T,H2V,H2W,H2X,H2Y,H2Z,H3A,H3B,H3C,H3E,H3G,H3H,H3J,H3K,H3L,H3M,H3N,H3P,H3R,H3S,H3T,H3V,H3W,H3X,H3Y,H3Z,H4A,H4B,H4C,H4E,H4G,H4H,H4J,H4K,H4L,H4M,H4N,H4P,H4R,H4S,H4T,H4V,H4W,H4X,H4Y,H4Z,H5A,H5B,H7A,H7B,H7C,H7E,H7G,H7H,H7J,H7K,H7L,H7M,H7N,H7P,H7R,H7S,H7T,H7V,H7W,H7X,H7Y,H8N,H8P,H8R,H8S,H8T,H8Y,H8Z,H9A,H9B,H9C,H9E,H9G,H9H,H9J,H9K,H9P,H9R,H9S,H9W,H9X,J0L,J0N,J0S,J0V,J1H,J2L,J3E,J3G,J3H,J3L,J3M,J3N,J3V,J3X,J3Y,J3Z,J4B,J4G,J4H,J4J,J4K,J4L,J4M,J4N,J4P,J4R,J4S,J4T,J4V,J4W,J4X,J4Y,J4Z,J5A,J5B,J5C,J5N,J5R,J5W,J5Y,J5Z,J6A,J6J,J6K,J6N,J6R,J6V,J6W,J6X,J6Y,J6Z,J7A,J7B,J7C,J7E,J7G,J7H,J7J,J7K,J7L,J7M,J7N,J7P,J7R,J7T,J7V,J7W,J8C,J9E';
const GVA = 'V0N,V1M,V2A,V2L,V2S,V2W,V2X,V2Y,V2Z,V3A,V3B,V3C,V3E,V3H,V3J,V3K,V3L,V3M,V3N,V3R,V3S,V3T,V3V,V3W,V3X,V3Y,V3Z,V4A,V4B,V4C,V4E,V4G,V4K,V4L,V4M,V4N,V4P,V4R,V4S,V4W,V5A,V5B,V5C,V5E,V5G,V5H,V5J,V5K,V5L,V5M,V5N,V5P,V5R,V5S,V5T,V5V,V5W,V5X,V5Y,V5Z,V6A,V6B,V6C,V6E,V6G,V6H,V6J,V6K,V6L,V6M,V6N,V6P,V6R,V6S,V6T,V6V,V6W,V6X,V6Y,V6Z,V7A,V7B,V7C,V7E,V7G,V7H,V7J,V7K,V7L,V7M,V7N,V7P,V7R,V7S,V7T,V7V,V7W,V7X,V7Y';
const validpostals = GTA.split(',').concat(GMA.split(',')).concat(GVA.split(','));
// validpostals.concat(GMA.split(','));
// console.log(validpostals.length,validpostals);

export default function Signup({locale}){
    const navigate = useNavigate();
    const intl = useIntl();
    const secondsTilClose = Math.max( 60*5, getTimeRemaining() ); // 5 minutes or more.

    // const { id } = useParams();
    const [ values, setValues ] = useState({
        address1:'',address2:'',city:'',postal:'',province:'ON',
        firstname:'',lastname:'',email:'',phone:'',lang:locale,
        optin:false,age:false,mealoption:'',drop:getCurrentDrop()});
    const [ errors, setErrors ] = useState({});
    const [ waiting, setWaiting ] = useState(false);
    const submitBtn = useRef(null);

    const fields = [ {group:'shipping',type:"text",id:"address1",required:true},
        {group:'shipping',type:"text",id:"address2"},
        {group:'shipping',type:"text",id:"city",required:true},
        {group:'shipping',type:"text",id:"postal",required:true, short:true},
        {group:'shipping',type:"dropdown",id:"province",required:true, short:true},
        {group:'personal',type:"text",id:"firstname",required:true, short:true},
        {group:'personal',type:"text",id:"lastname",required:true, short:true},
        {group:'personal',type:"email",id:"email",required:true},
        {group:'personal',type:"text",id:"phone",required:true},
        {group:'',type:"hidden",id:"mealoption",required:true},
        {group:'',type:"checkbox",id:"optin",required:true},
        {group:'',type:"checkbox",id:"age",required:true}
    ];

    const handleInputChange = input => e => {
        submitBtn.current.disabled = false;
        
        const val = { ...values };
        const err = { ...errors };
        if (input==='province'){
            val[input] = e.value;
        } else if (e.target.type==='checkbox'){
            val[input] = e.target.checked;
        } else {
            val[input] = e.target.value;
        }
        delete err[input];

        // immediate feedback on postals
        if (input==='postal'){
            // at least 3 characters?
            if (e.target.value.length>=3){
                // get first 3 chars
                if (checkValidPostal(e.target.value)===0) {
                    err[input] = true;
                    err['notdeliverable'] = true;
                }
            }
        }
        
        setValues(val);
        setErrors(err);
    }
    function handleMealSelect(e){
        submitBtn.current.disabled = false;

        const val = { ...values };
        const err = { ...errors };
        val['mealoption'] = e.target.id;
        delete err['mealoption'];

        setValues(val);
        setErrors(err);
    }
    function checkValidPostal(str){
        const thiszip = str.substr(0,3).toUpperCase();
        // console.log('check',thiszip);
        if(!validpostals.includes(thiszip)){
            // console.log('invalid postal!');
            return 0; // hard failure - outside of delivery zone.
        } else if (str.length<6 || str.length>7){
            return 1; // soft failure - invalid format, but first 3 letters check out.
        }
        return 2; // valid
    }
    function checkValidEmail(email){
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    function collapseModal(){
        const err = { ...errors };
        delete err['notdeliverable'];
        delete err['duplicate'];
        setErrors(err);
    }

    async function handleSubmit(){
        // disable submit button
        submitBtn.current.disabled = true;

        // validate fields
        const err = {num:0};
        fields.forEach( (field,index) => {
            // const input = document.getElementById(field.id);
            if (field.required && ( values[field.id]==='' || !values[field.id]) ){ 
                err[field.id] = true;
                err.num++;
            } else if (field.type==='email') {
                if (!checkValidEmail(values[field.id])){
                    err[field.id] = true;
                    err.num++;
                } 
            } else if (field.id==='postal'){
                const validpostal = checkValidPostal(values[field.id]);
                if (validpostal===0){
                    err[field.id] = true;
                    err['notdeliverable'] = true;
                    err.num++;
                } else if (validpostal===1){
                    err[field.id] = true;
                    err.num++;
                }
            }
            // data[field.id] = values[field.id];
        });
        console.log(err);
        setErrors(err);

        // submit!
        if(err.num===0) {
            console.log('submit!',values);
            setWaiting(true);
            const result = await submitEntry(values);
            // console.log(result);
            
            if (result.status===200){
                setWaiting(false);
                if (locale===LOCALES.ENGLISH){
                    navigate('/thankyou');
                } else {
                    navigate('/fr/thankyou');
                }
            } else {
                setWaiting(false);
                // todo. set error based on code.
                setErrors({duplicate:true,num:1});
            }
            
        }
    }

    function timeout(){
        if (waiting) return;
        if (locale===LOCALES.ENGLISH){
            navigate('/');
        } else {
            navigate('/fr/');
        }
        // submitBtn.current.disabled = true;
    }

    return (
        <div className="signup">
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
                
            <div className="delivery-areas">
                <h4>{translate('splash.areas.title')}</h4>
                <ul>
                    <li>{translate('splash.areas.1')}</li>
                    <li>{translate('splash.areas.2')}</li>
                    <li>{translate('splash.areas.3')}</li>
                </ul>
            </div>

            <p>{translate('signup.body')}</p>
            <p><MiniCountdown onComplete={timeout} start={secondsTilClose} /></p>


            <div className="row">
                <div className="col">
                    <h4>{translate('signup.shipping.title')}</h4>
                    {fields.map( (field,index) => {
                        if (field.group==='shipping'){
                            if (field.type==='text'){
                                return (<div className={'wrap-'+field.type+(errors[field.id]?' error':'')+(field.short?' short':'')} key={index}>
                                    <input type={field.type} id={field.id} placeholder={intl.formatMessage({ id: 'signup.'+field.group+'.'+field.id })} onChange={handleInputChange(field.id)} value={values[field.id]} />
                                </div>)
                            } else if (field.type==='dropdown'){
                                const options = [{value:'ON',label:'ON'}, {value:'QC',label:'QC'}, {value:'BC',label:'BC'}];
                                return (<div className={'wrap-'+field.type+(errors[field.id]?' error':'')+(field.short?' short':'')} key={index}>
                                    <Select id={field.id} options={options} onChange={handleInputChange(field.id)} defaultValue={options[0]} classNamePrefix="CustomSelect" />
                                </div>)
                                //   placeholder={intl.formatMessage({ id: 'signup.'+field.group+'.'+field.id })} />
                            }
                            return false;
                        } else {
                            return false;
                        }
                        } )}
                </div>
                <div className="col">
                    <h4>{translate('signup.personal.title')}</h4>
                    {fields.map( (field,index) => {
                        if (field.group==='personal'){
                            return (<div className={'wrap-'+field.type+(errors[field.id]?' error':'')+(field.short?' short':'')} key={index}>
                                {/* <label htmlFor={field.id}>{translate('signup.'+field.group+'.'+field.id)}</label> */}
                                <input type={field.type} id={field.id} placeholder={intl.formatMessage({ id: 'signup.'+field.group+'.'+field.id })} onChange={handleInputChange(field.id)} defaultValue={values[field.id]} />
                            </div>)
                        } else {
                            return false;
                        }
                        } )}
                </div>
            </div>

            <h3 className={errors['mealoption']?'error':''}>{translate('signup.meal.title')}</h3>
            <div className="meal-options row">
                <div>
                <button className={`green-button ${values['mealoption']==='mealoption1'?'selected':''}`} id="mealoption1" onClick={handleMealSelect}>{translate('signup.meal.option1.label')}</button>
                <label htmlFor="mealoption1">{translate('signup.meal.option1.desc')}</label>
                </div>
                <div>
                <button className={`blue-button ${values['mealoption']==='mealoption2'?'selected':''}`} id="mealoption2" onClick={handleMealSelect}>{translate('signup.meal.option2.label',{br:<br/>})}</button>
                <label htmlFor="mealoption2">{translate('signup.meal.option2.desc')}</label>
                </div>
                <input type="hidden" id="mealoption" value={values['mealoption']} />
            </div>

            <div className="radios row">
                <div className={errors['optin']?'error':''}>
                    <input type="checkbox" id="optin" checked={values['optin']} onChange={handleInputChange('optin')} />
                    <label htmlFor="optin">{translate('signup.optin')}</label>
                </div>
                <div className={errors['age']?'error':''}>
                    <input type="checkbox" id="age" checked={values['age']} onChange={handleInputChange('age')} />
                    <label htmlFor="age">{translate('signup.age')}</label>
                </div>
            </div>
               
                {errors.num>0
                    ?<p className="error">{translate('signup.error')}</p>
                    :<p>{translate('signup.confirm')}</p>
                }
                <input type="submit" value={intl.formatMessage({ id: 'signup.cta' })} onClick={handleSubmit} className="black-button" ref={submitBtn} />
            </Tween>

            {errors.notdeliverable && <>
                <div className="modal">
                    <h2>{translate('signup.error.postal.title')}</h2>
                    <p>{translate('signup.error.postal.body')}</p>
                    <button onClick={collapseModal}>{translate('signup.error.postal.cta')}</button>
                </div>
            </>}
            {errors.duplicate && <>
                <div className="modal">
                    <h2>{translate('signup.error.dupe.title')}</h2>
                    <p>{translate('signup.error.dupe.body')}</p>
                    <button onClick={collapseModal}>{translate('signup.error.dupe.cta')}</button>
                </div>
            </>}
        </div>
    );
}