import { LOCALES } from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Les saveurs de la Terre du Milieu',
        'close': 'Fermer',
        'legal': 'Legal',
        'faq': 'Renseignements généraux',
        'privacy': 'Politique de confidentialité',
        'terms': 'Conditions d’utilisation',
        'contact': 'Nous joindre',
        'languageToggle': 'EN',

        'cookie.title': 'Vos paramètres de cookies',
        'cookie.body': 'Nous utilisons des cookies, ou témoins, pour améliorer votre expérience de navigation, analyser le trafic de notre site Web et comprendre d’où viennent nos visiteurs. Si vous consentez aux cookies, cliquez sur « Accepter ». Si vous le souhaitez, vous pouvez modifier vos cookies dans les paramètres de votre navigateur. Pour plus d’informations, consultez notre ',
        'cookie.cta': 'Accepter',

        'splash.title' : 'Ce soir, nous soupons en Terre du Milieu.',
        'splash.countdown.label': 'Temps avant la prochaine livraison ',
        'splash.countdown.mins': 'minute',
        'splash.countdown.hrs': 'heure',
        'splash.upcoming.title': 'Dates de livraison à venir',
        'splash.upcoming.format': '0{d}/{m}/2022 20 h HNE / 17 h HNP',
        'splash.body': 'Célébrez le dernier épisode de la saison de <i>Les anneaux de pouvoir</i> en dégustant un repas de la Terre du Milieu. Offrez-vous un ensemble repas gourmand et exclusif, comprenant des couverts et des assiettes inspirés de la Terre du Milieu, livré directement chez vous!{br}{br}Optez pour le savoureux festin végétarien des Piévelus ou bien pour le délicieux repas des Nains avec de l’agneau, deux créations culinaires du célèbre chef Marcus Samuelsson.',
        'splash.subtitle': 'Fonctionnement de la livraison',
        'splash.body2': 'Les quantités sont limitées. Alors, inscrivez les dates de livraison dans votre calendrier, réglez votre cadran solaire et préparez-vous à faire voyager vos papilles en Terre du Milieu.{br}{br}Nos ensembles repas seront livrés exclusivement les 7, 8 et 9 octobre 2022 à 20 h (HNE) et 17 h (HNP), soit une semaine avant la diffusion du dernier épisode de la saison de <i>Les anneaux de pouvoir</i>.{br}{br}Suivez @PrimeVideoCA pour\u00a0plus\u00a0de\u00a0détails.',
        'splash.areas.title': 'Édition limitée pour les\u00a0régions\u00a0suivantes\u00a0:',
        'splash.areas.1': 'Région du Grand Toronto',
        'splash.areas.2': 'Région du Grand Montréal',
        'splash.areas.3': 'Région du Grand Vancouver',
        'splash.cta': 'COMMANDER VOTRE ENSEMBLE REPAS',
        'splash.closed.title': 'Désolé, cette offre n’est plus disponible',
        'splash.closed.body': 'Les ensembles repas « Les saveurs de la Terre du Milieu » ne sont plus offerts. Vous pouvez toujours visionner la série <i>Le seigneur des anneaux :</i> <i>Les anneaux de pouvoir</i> sur Amazon Prime Video. Ne manquez pas la diffusion du dernier épisode de la saison le 14 octobre prochain.{br}{br}Et si jamais vous désirez cuisiner vous-même les recettes inspirées de la Terre du Milieu, nous vous invitons à utiliser votre appareil compatible Alexa et à lui dire « Alexa, montre-moi les recettes de <i>Les anneaux de pouvoir</i> ».',

        'signup.body': 'Remplissez le formulaire ci-dessous et vous pourriez remporter l’un des ensembles repas des saveurs de la Terre du Milieu.',
        'signup.time': 'Temps restant',
        'signup.shipping.title': 'Informations sur la livraison',
        'signup.shipping.address1': '123, rue Principale*',
        'signup.shipping.address2': 'Ligne 2 – Adresse d’unité (facultatif)',
        'signup.shipping.city': 'Ville*',
        'signup.shipping.postal': 'M1R 2B3*',
        'signup.shipping.province': 'ON*',
        'signup.personal.title': 'Renseignements personnels',
        'signup.personal.firstname': 'Prénom*',
        'signup.personal.lastname': "Nom de famille*",
        'signup.personal.email': 'Adresse courriel*',
        'signup.personal.phone': 'Numéro de téléphone*',
        'signup.meal.title': 'Sélectionnez votre repas',
        'signup.meal.option1.label': 'Le festin des Piévelus (végétarien)',
        'signup.meal.option1.desc': 'Chou rôti servi sur farro avec champignons sauvages, noisettes et mûres',
        'signup.meal.option2.label': 'Le repas{br}des Nains',
        'signup.meal.option2.desc': 'Côtelettes d’agneau épicées avec betteraves rôties et labneh aux herbes',
        'signup.optin': 'Je souhaite recevoir des mises à jour et des communications sur les ensembles repas.*',
        'signup.age': 'Je reconnais avoir 18 ans ou plus.*',
        'signup.confirm': 'Veuillez confirmer toutes les informations ci-dessus. Elles ne pourront pas être modifiées ultérieurement.',
        'signup.error': 'Merci de remplir tous les champs manquants.',
        'signup.cta': 'Soumettre',

        'signup.error.postal.title': 'Il semble que vous soyez en dehors de la zone de livraison.',
        'signup.error.postal.body': 'Désolé, nos chevaux livrent seulement dans les régions du Grand Toronto, du Grand Montréal et du Grand Vancouver.',
        'signup.error.postal.cta': 'Retour',
        'signup.error.dupe.title': 'Il semble que vous soyez déjà inscrit.',
        'signup.error.dupe.body': 'Désolé, vous ne pouvez participer qu’une seule fois par jour. Restez à l’affût de notre prochaine livraison!',
        'signup.error.dupe.cta': 'Retour',

        'thankyou.title': 'Merci!',
        'thankyou.body': 'Nous vous informerons si vous avez remporté un ensemble repas. N’oubliez pas de vérifier vos courriels indésirables.',

        'confirm.title': 'Merci d’avoir confirmé votre livraison!',
        'confirm.body': 'Vous recevrez un courriel avec les informations de suivi une fois l’ensemble repas expédié.',
        'confirm.error': 'Désolé, vous n’avez malheureusement pas confirmé votre commande dans les 24 heures. Votre ensemble repas ne sera donc pas livré.',

        'faq.title': 'Renseignements généraux',
        'faq.body': `<h4>Expédition</h4>
        <p>Une fois l’ensemble repas expédié, vous recevrez un courriel ou un SMS avec toutes les informations de suivi.</p>
         
        <h4>Modification de l’adresse de livraison</h4>
        <p>Les adresses de livraison ne peuvent pas être modifiées. Nous n’expédierons qu’à l’adresse indiquée lors de votre inscription.</p>
         
        <h4>Annulation d’une commande</h4>
        <p>Une fois que vous avez réclamé votre ensemble repas, nous ne pouvons pas annuler ni modifier l’envoi.</p>
         
        <h4>Suivi des commandes</h4>
        <p>Veuillez vous référer aux informations de suivi dans le courriel ou le SMS que vous recevez.</p>
         
        <h4>Politique de retour</h4>
        <p>Nous n’accepterons aucun retour des ensembles repas.</p>`

    }
}