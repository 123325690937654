import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom'; 
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { I18nProvider, LOCALES } from './i18n'; //language files
import { Splash, Signup, ThankYou, Confirm, Faq, Terms } from './components/pages';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import Footer from './components/Footer';
import { createBrowserHistory } from 'history';
import CookieConsent from 'react-cookie-consent';
import { getContestState, getNextDrop, getTimeRemaining } from './util';

import './App.scss';
import { init } from './components/Model';
import translate from './i18n/translate';
// import { render } from '@testing-library/react';

const GAID = 'G-6KFSQZ5NSE';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
ReactGA.initialize(GAID);

const history = createBrowserHistory({window});
history.listen(({action,location}) => {
    // console.log("tracking page view: ",location.pathname);
    ReactGA.send({hitType:'pageview',page:location.pathname});
});


class App extends Component {
  
  state = {
    locale : LOCALES.ENGLISH,
    contestState : null, //getContestState(),
    nextDrop: null, // getNextDrop(),
    timeRemaining: null, // getTimeRemaining()
    // navigate: useNavigate()
    // activeEN: true
  }

  //Language change
  langChangeHandle = () => {
      // console.log('current',this.state.locale);
      if (this.state.locale===LOCALES.ENGLISH){
        this.setState({locale:LOCALES.FRENCH});
      } else {
        this.setState({locale:LOCALES.ENGLISH});
      }
  }

  refreshState(){
    // console.log('refresh state');
    this.setState({
      contestState : getContestState(),
      nextDrop: getNextDrop(),
      timeRemaining: getTimeRemaining()
    })
  }

  componentDidMount(){


    // set locale based on path.
    const thispath = window.location.pathname;
    ReactGA.pageview(thispath);
    if (thispath.indexOf('fr')>-1){
      this.setState({locale:LOCALES.FRENCH});
    }

    const apiPath = BACKEND_URL;
    
    // declare the data fetching function
    const fetchData = async () => {
      await init(apiPath,'');
      this.refreshState();
    }

    // call the function
    fetchData()
    // make sure to catch any error
    .catch(console.error);
  }


  render(){ 
    // const {  } = this.state;
    // only render router if settings have been set.
    return (
      <div className="App">
        <HistoryRouter history={history}>
          <I18nProvider locale={this.state.locale}>
            <Header handleLanguage={this.langChangeHandle} locale={this.state.locale} />
          </I18nProvider>
          
          {this.state.contestState!==null &&
            <Routes>
              {/* ENGLISH ROUTES */}
              <Route exact path="/" element={<I18nProvider locale={LOCALES.ENGLISH}><Splash contestState={this.state.contestState} refreshState={this.refreshState.bind(this)} locale={LOCALES.ENGLISH} /></I18nProvider>} />
              <Route exact path="/thankyou/" element={<I18nProvider locale={LOCALES.ENGLISH}><ThankYou /></I18nProvider>} />
              <Route exact path="/confirm/:id" element={<I18nProvider locale={LOCALES.ENGLISH}><Confirm /></I18nProvider>} />
              <Route exact path="/faq/" element={<I18nProvider locale={LOCALES.ENGLISH}><Faq /></I18nProvider>} />
              <Route exact path="/terms/" element={<I18nProvider locale={LOCALES.ENGLISH}><Terms locale={LOCALES.ENGLISH} /></I18nProvider>} />
              {/* prevent navigating to sign up outside of contest window. */}
              { this.state.contestState===1 && <Route exact path="/signup/" element={<I18nProvider locale={LOCALES.ENGLISH}><Signup locale={LOCALES.ENGLISH} /></I18nProvider>} />}
              {/* FRENCH ROUTES */}
              <Route exact path="/fr" element={<I18nProvider locale={LOCALES.FRENCH}><Splash contestState={this.state.contestState} refreshState={this.refreshState.bind(this)} locale={LOCALES.FRENCH} /></I18nProvider>} />
              <Route exact path="/fr/thankyou/" element={<I18nProvider locale={LOCALES.FRENCH}><ThankYou /></I18nProvider>} />
              <Route exact path="/fr/confirm/:id" element={<I18nProvider locale={LOCALES.FRENCH}><Confirm /></I18nProvider>} />
              <Route exact path="/fr/faq/" element={<I18nProvider locale={LOCALES.FRENCH}><Faq /></I18nProvider>} />
              <Route exact path="/fr/terms/" element={<I18nProvider locale={LOCALES.ENGLISH}><Terms locale={LOCALES.FRENCH} /></I18nProvider>} />
              {/* prevent navigating to sign up outside of contest window. */}
              { this.state.contestState===1 && <Route exact path="/fr/signup/" element={<I18nProvider locale={LOCALES.FRENCH}><Signup locale={LOCALES.FRENCH} /></I18nProvider>} />}
            </Routes>
          }

          <I18nProvider locale={this.state.locale}>
            <Footer locale={this.state.locale} />
            <CookieConsent location="" buttonClasses='button' containerClasses='cookie-consent' buttonWrapperClasses='cookie-consent-buttons' overlayClasses="cookie-consent-overlay" disableStyles="true" buttonText={translate('cookie.cta')}>
              <h3>{translate('cookie.title')}</h3>
              <p>{translate('cookie.body')}
              <a href={'https://www.amazon.ca/gp/help/customer/display.html?nodeId=918814&ref_=footer_privacy&language='+this.state.locale} target="_blank" rel="noreferrer">{translate('privacy')}</a>.
              </p>
            </CookieConsent>
          </I18nProvider>
        </HistoryRouter>
      </div>
    );
  }
}

export default App;
