import { useEffect, useState } from 'react';
import { Tween } from 'react-gsap';
import { useParams } from 'react-router-dom';
import translate from '../../i18n/translate';
import { confirmEntry } from '../Model';

export default function Confirm(){
    const { id } = useParams();
    const [ status, setStatus ] = useState(0);

    useEffect(()=>{
        // send confirmation to backend.
        console.log('check',id);

        // declare the data fetching function
        const fetchData = async () => {
            const data = await confirmEntry(id);
            console.log(data);
            setStatus( Number(data.status) );
        }

        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);
    },[])

    return (
        <div>
            {(status===200 || status===409) &&
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
            <h1>{translate('confirm.title')}</h1>
            <p>{translate('confirm.body')}</p>
            </Tween>}
            {status===411 &&
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
            <p>{translate('confirm.error')}</p>
            </Tween>}
        </div>
    );
}