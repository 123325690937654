// import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import translate from '../../i18n/translate';
import { LOCALES } from '../../i18n';

import Feast from '../../assets/Website_EN_v4.jpg';
import FeastFR from '../../assets/Website_FR_v4.jpg';
import Countdown from '../Countdown';
import MiniCountdown from '../MiniCountdown';
import { getNextDrop, getTimeRemaining } from '../../util';





export default function Splash({contestState,refreshState,locale}){
    const navigate = useNavigate();
    const secondsTilNextDrop = getNextDrop();
    const secondsTilClose = getTimeRemaining();
    
    function handleOnClick(){
        if (locale===LOCALES.ENGLISH){
            navigate('/signup');
        } else {
            navigate('/fr/signup');
        }
    }

    return (
        <div className='splash'>
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
            <h1>{(contestState===0 
                ? translate('splash.closed.title')
                : translate('splash.title') )}
                </h1>

            <div>
            { contestState===-1 && <Countdown onComplete={refreshState} start={secondsTilNextDrop}/> }
            { contestState===1 && <>
                    <button onClick={handleOnClick}>{translate('splash.cta')}</button> 
                    <MiniCountdown onComplete={refreshState} start={secondsTilClose}/>
                </>}
            { contestState===0 && <p>{translate('splash.closed.body',{br:<br/>,i:chunks=><i>{chunks}</i>})}</p> }
            </div>
            
            <img src={locale===LOCALES.ENGLISH?Feast:FeastFR} alt="Feast" className={contestState===0?'hidden':''} />
            <p className={contestState===0?'hidden':''}>{translate('splash.body',{br:<br/>,i:chunks=><i>{chunks}</i>})}</p>
            <h3 className={contestState===0?'hidden':''}>{translate('splash.subtitle')}</h3>
            <p className={contestState===0?'hidden':''}>{translate('splash.body2',{br:<br/>,i:chunks=><i>{chunks}</i>})}</p>
            <div className={contestState===0?'hidden':'delivery-areas'}>
                <h4>{translate('splash.areas.title')}</h4>
                <ul>
                    <li>{translate('splash.areas.1')}</li>
                    <li>{translate('splash.areas.2')}</li>
                    <li>{translate('splash.areas.3')}</li>
                </ul>
            </div>
            
            </Tween>
        </div>
    );
}